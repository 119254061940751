<template>
<div>
  <el-card style="height: 100%">
    <div class="edit-header">
      <span>规划设计方案审查回复单</span>
      <div>
        <el-button type="primary" @click="print">打印</el-button>
        <el-button type="primary"  @click="onSubmit"
        >保存</el-button>
      </div>
    </div>
    <el-form
        :label-position="labelPosition"
        label-width="100px"
        :model="form"
        :rules="rules"
    >
    <el-form-item label="申报单位:">
      <el-input v-model="form.SBDW" disabled></el-input>
    </el-form-item>
    <el-row :gutter="24">
      <el-col :span="12">
        <el-form-item label="联系人:">
          <el-input v-model="form.LXR" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="联系电话:">
          <el-input v-model="form.LXDH" disabled></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="项目名称:">
      <el-input v-model="form.XMMC" disabled></el-input>
    </el-form-item>
    <el-form-item label="建设地点:">
      <el-input v-model="form.JSDD" disabled></el-input>
    </el-form-item>
    <el-row :gutter="24">
      <el-col :span="12">
        <el-form-item label="用地面积:" prop="YDMJ">
          <el-input   v-model="form.YDMJ"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="建筑面积:" prop="JZMJ">
          <el-input   v-model="form.JZMJ"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="审查阶段:">
      <el-input v-model="form.SCJD" ></el-input>
    </el-form-item>
      <el-form-item style="min-height: 300px" label="回复意见:" prop="HFYJ">
        <el-input  :autosize="{ minRows: 1 }"  type="textarea" v-model="form.HFYJ" ></el-input>
      </el-form-item>
     <el-row :gutter="24">
       <el-col :span="14"> </el-col>
       <el-col :span="10">
         <el-form-item style="margin-top: 10px;" label="日期:" prop="RQ" >
           <el-date-picker   value-format="YYYY-MM-DD"   type="date" style="width: 100%"  v-model="form.RQ"></el-date-picker>
         </el-form-item>
       </el-col>
     </el-row>
    </el-form>
  </el-card>
 <div v-show="false">
   <div id="form">
     <h1 style="text-align: center;font-size: 25px;font-weight: 400;margin: 30px 0">规划设计方案审查回复单</h1>
     <div style="margin-left: 60%;margin-bottom: 20px;font-size: 14px">案卷号 {{keepform.AJH}}</div>
     <table style="font-size: 14px;">
       <tr style="height: 35px">
         <td colspan="1" style="width: 100px;text-align: center">
           <div>申报单位</div>
         </td>
         <td colspan="3" style="text-indent: 1em;"> {{keepform.SBDW}} </td>
       </tr>
       <tr style="height: 35px">
         <td style="width: 100px;text-align: center">
           <div>联系人</div>
         </td>
         <td style="text-indent: 1em;"> {{keepform.LXR}} </td>
         <td style="width: 100px;text-align: center">
           <div>联系电话</div>
         </td>
         <td style="text-indent: 1em;"> {{keepform.LXDH}} </td>
       </tr>
       <tr style="height: 35px">
         <td colspan="1" style="width: 100px;text-align: center">
           <div>项目名称</div>
         </td>
         <td colspan="3" style="text-indent: 1em;">  {{keepform.XMMC}}  </td>
       </tr>
       <tr style="height: 35px">
         <td colspan="1" style="width: 100px;text-align: center">
           <div>建设地点</div>
         </td>
         <td colspan="3" style="text-indent: 1em;">  {{keepform.JSDD}}  </td>
       </tr>
       <tr style="height: 35px">
         <td style="width: 100px;text-align: center">
           <div>用地面积</div>
         </td>
         <td style="text-indent: 1em;">{{keepform.YDMJ}} </td>
         <td style="width: 100px;text-align: center">
           <div>建筑面积</div>
         </td>
         <td style="text-indent: 1em;">{{keepform.JZMJ}}</td>
       </tr>
       <tr style="height: 35px">
         <td colspan="1" style="width: 100px;text-align: center">
           <div>审查阶段</div>
         </td>
         <td colspan="3" style="text-indent: 1em;">{{keepform.SCJD}}</td>
       </tr>
       <tr style="height: 300px">
         <td colspan="4" valign="top">
           <div style="margin-top: 10px">回复意见:</div>
           <div style="margin-top: 10px;padding-left: 5px;word-wrap: break-word;white-space:pre-wrap;width: 100vw">
             {{keepform.HFYJ}}
           </div>
         </td>
       </tr>
     </table>
     <div style="margin-left: 70%;margin-top: 40px;font-size: 14px">日期: {{keepform.RQ}}</div>
   </div>
 </div>
</div>
</template>

<script>
import print from "print-js";
import {ElMessage} from "element-plus";
import dayjs from "dayjs";

export default {
  name: "sjfasc",
  data() {
    return {
      instanceId: null,
      rules: {
        YDMJ:[
          {
            required: true,
            message: '请输入用地面积',
            trigger: 'blur',
          }
        ],
        JZMJ:[
          {
            required: true,
            message: '请输入建筑面积',
            trigger: 'blur',
          }
        ],
        HFYJ:[
          {
            required: true,
            message: '请输入回复意见',
            trigger: 'blur',
          }
        ],
        RQ:[
          {
            required: true,
            message: '请选择时间',
            trigger: 'blur',
          }
        ]
      },
      labelPosition: 'right',
      keepform:{
        BJID: '',
        AJH: '',
        HFYJ: '',
        RQ: '',
        SBDW: '',
        LXR: '',
        LXDH: '',
        XMMC: '',
        JSDD: '',
        YDMJ: '',
        JZMJ: '',
        SCJD: ''
      },
      form: {
        BJID: '',
        AJH: '',
        HFYJ: '',
        RQ: '',
        SBDW: '',
        LXR: '',
        LXDH: '',
        XMMC: '',
        JSDD: '',
        YDMJ: '',
        JZMJ: '',
        SCJD: ''
      },
    }
  },
  methods: {
    print() {
     this.$nextTick(()=> {
       print({
         printable: "form",
         type: "html",
         scanStyles: false,
         css: "/static/sjfasc.css",
         documentTitle: "",
       });
     })
    },
    async onGet () {
      this.instanceId = this.$route.query.instanceId
      // const { data } = await getInformation (this.instanceId)
      try {
        await this.$ajax
            .get(
                this.$appConfig.apiUrls.ywxtApi + "/api/YW/Getghsjfaschfd?BJID=" + this.instanceId
            )
            .then((response) => {
              if (response.data.IsSuccess) {
                this.form = response.data.Data
              } else {
                console.log(response.data.ErrorMessage);
              }
            });
      } catch (error) {
        console.log(error);
      }
    },
    async fetch() {
      this.form.BJID = this.$route.query.instanceId
      let me = this
      try {
        await this.$ajax
            .get(
                this.$appConfig.apiUrls.ywxtApi +
                "/api/YW/getbjspxx?BJID=" +
                this.form.BJID
            )
            .then((response) => {
              if (response.data.IsSuccess) {
                // console.log('打印审批表数据：',response);
                let res = response.data.Data;
                let yjj = me.form.YJDATA
                me.form = res
                me.form.YJDATA = yjj
                me.form.KSAJH = me.$store.state.AJH;
                me.keepform.AJH = me.$store.state.AJH;
                this.getlahz()
              } else {
                me.form = response.data.Data;
              }

            });
      } catch (error) {
        ElMessage({
          type: 'error',
          message: error,
        })
      }
    },
    async getlahz() {
      let me = this;
      try {
        await this.$ajax
            .get(
                this.$appConfig.apiUrls.ywxtApi +
                "/api/YW/Getlahz?Id=" +
                me.$route.query.instanceId
            )
            .then((response) => {
              if (response.data.IsSuccess) {
                let res = response.data.Data;
                me.form.SBDW = res.JSDW
                me.form.XMMC = res.XMMC
                me.form.LXR = res.LXR
                me.form.LXDH = res.SJH
                me.form.JSDD = res.JSWZ
                if(me.form.RQ=='Invalid Date'||!me.form.RQ){
                  me.form.RQ = dayjs(new Date()).format('YYYY-MM-DD')
                }
                // me.getSpsx()
                me.keepform =  JSON.parse(JSON.stringify(me.form))
                me.keepform.AJH = me.$store.state.AJH;
                me.keepform.RQ =  dayjs(me.form.RQ).format('YYYY年MM月DD日')
              } else {
                console.log(response.data.ErrorMessage);
              }
            });
      } catch (error) {
        ElMessage({
          type: 'error',
          message: error,
        })
      }
    },
    async onSubmit() {
      let me = this;
        try {
          await this.$ajax
              .post(
                  this.$appConfig.apiUrls.ywxtApi +'/api/YW/AddorUpdateghsjfaschfd?BJID=' + this.instanceId,
                  this.form
              )
              .then(function (response) {
                if (response.data.IsSuccess) {
                  me.keepform =  JSON.parse(JSON.stringify(me.form))
                  me.keepform.RQ =  dayjs(me.form.RQ).format('YYYY年MM月DD日')
                  ElMessage({
                    type: "success",
                    message: "保存成功!",
                  });

                } else {
                  ElMessage({
                    type: "info",
                    message: "保存失败!",
                  });

                }
              });
        } catch (error) {
          console.log(error);
        }
    },
  },
  created() {
    this.fetch()
    this.onGet()
  }
}
</script>

<style scoped>
:deep(.el-textarea__inner) {
  min-height: 300px !important;
}
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10PX;
}
.el-form-item{
  margin-bottom: 18px;
}

</style>
